@import "settings";

//@import "_common/reset";
//@import "_common/grid";
@import '~glightbox/dist/css/glightbox';
@import "~normalize.css";
@import "~swiper/swiper-bundle.css";
@import "_vendors/smart-grid";
@import '~nouislider/distribute/nouislider.css';

$hamburger-types     : (collapse);
$hamburger-layer-color: #ffffff;
$hamburger-padding-x           : 14px;
$hamburger-padding-y           : 12px;
$hamburger-layer-width         : 28px;
$hamburger-layer-height        : 4px;
$hamburger-layer-spacing       : 6px;
$hamburger-layer-border-radius : 4px;
$hamburger-hover-opacity       : 1;

@import "~hamburgers/_sass/hamburgers/hamburgers.scss";


@import "_parts/fonts";
@import "_parts/base";
@import "_parts/typo";
@import "_parts/article";

//@import "_parts/forms";
@import "_parts/image_icons";

@import "_blocks/header/index";
@import "_blocks/footer/index";
@import "_blocks/components/index";

@import "_pages/index";


@import "_vendors/_modal";
@import "_vendors/_tabs";
//@import "_vendors/_slick";
@import "_vendors/_sticky";
