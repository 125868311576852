.error-page {
    @include col;
    @include size(12);
    @include size-xs(12);
    margin-top: 40px;
    display: flex;
    align-items: center;
    flex-direction: column;
    .message {
        font-size: 32px;
    }
    .error-code {
        font-size: 44px;
    }
}



