.download-list {
    display: flex;
    flex-wrap: wrap;
    &__title  {
        @include font(500);
        font-size: 16px;
        color: #111111;
        flex-basis: 30%;
        margin: 0;
        flex-grow: 1;
        @include md-block {
            flex-basis: 40%;
        }
        @include xs-block {
            flex-basis: 100%;
            margin-bottom: 22px;
        }
    }
    &__block {
        flex-basis: 60%;
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        list-style: none;
        @include md-block {
            flex-basis: 50%;
        }
        @include xs-block {
            flex-basis: 100%;
            margin-bottom: 22px;
        }
    }
    &__item {
        flex-basis: 50%;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        &::after {
            display: none;
        }
        @include md-block {
            flex-basis: 100%;
        }
    }
    &__icon {
        flex-shrink: 0;
        display: inline-block;
        width: 34px;
        height: 34px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        margin-right: 14px;
    }
    &__link {
        font-size: 14px;
        @include font;
        color: #312D2C;
        padding-right: 10px;
    }
}