.menu {
    border-bottom: 1px solid #E5E5E5;
    position: relative;
    &__block {
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0;
        @include lg-block {
            flex-wrap: wrap;
        }
        @include sm-block {
            flex-wrap: nowrap;
        }
        @include xs-block {
            flex-wrap: wrap;
        }
    }
    &__item {
        margin-right: 50px;
        position: static;
        margin-bottom: 0;
        flex-shrink: 0;
        &::after {
            display: none;
        }
        &:hover {
            & .menu__link {
                font-weight: 400;
                color: $primary-color;
            }
            & .menu__inner-wrap {
                display: grid;
            }
            .menu__link::after {
                background-color: $primary-color;
            }
            & .icon-arrow-menu {
                opacity: 0;
            }
            @include xs-block {
                & .menu__inner-wrap {
                    display: none;
                }
                & .icon-arrow-menu {
                    opacity: 1;
                }
            }
        }
        @include xl-block {
            margin-right: auto;
        }
        @include xm-block {
            margin-right: 15px;
            flex-grow: 1;
            flex-shrink: 0;
        }
        @include lg-block {
            margin-right: 20px;
            flex-grow: 1;
            flex-shrink: 0;
            display: flex;
            align-items: center;
        }
        @include md-block {
            flex-basis: auto;

        }
        @include sm-block {
            flex-shrink: 1;

        }
        @include xs-block {
            flex-basis: 100%;
            display: flex;
            flex-wrap: wrap;
            margin-right: 0;
            & .menu__link + .menu__inner-wrap--active {
                display: grid;
            }
        }
    }
    &__link {
        color: #111111;
        padding: 16px 0;
        font-size: 16px;
        font-family: $font;
        text-decoration: none;
        display: block;
        position: relative;
        & .icon-arrow-menu {
            margin-left: 6px;
            stroke: #000;
            @include xs-block {
                margin-left: 30px;
                width: 18px;
                height: 18px;
            }
        }
        &::after {
            position: absolute;
            content: "";
            left: 0;
            right: 0;
            bottom: -1px;
            height: 2px;
        }
        @include xl-block {
            font-size: 15px;
        }
        @include xm-block {
            font-size: 14px;
        }
        @include lg-block {
            flex-grow: 1;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 14px;
        }
        @include md-block {
            font-size: 13px;
        }
        @include xs-block {
            font-size: 18px;
            flex-basis: auto;
            flex-grow: 0;
        }

    }
    &__inner-wrap {
        display: none;
        grid-template-columns: 1fr;
        grid-column-gap: 10px;
        position: absolute;
        background-color: rgba(#fff,0.95);
        box-shadow: -30px 30px 40px rgba(#000,0.1);
        padding: 20px 28px;
        top: calc(100% + 1px);
        z-index: 2;
        @include xs-block {
            position: static;
            grid-template-columns: 1fr;
            flex-basis: 100%;

        }
    }
    &__inner-section {
        max-width: none;
        //margin-bottom: 20px;
    }
    &__inner-title {
        display: inline-block;
        font-family: $font;
        font-size: 14px;
        color: #3A3C49;
        margin-bottom: 14px;
    }
    &__inner-block {
        list-style: none;
        padding: 0;
        margin: 0;
        padding-left: 10px;
    }
    &__inner-elem {
            margin-bottom: 14px;
            position: static;
    }
    &__inner-link {
        color: #8F94B2;
        font-size: 14px;
        text-decoration: none;
        &:hover {
            color: $primary-color;
        }
    }
}