.list-links {
    padding: 0; 
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    &--icon {

    }
    &__item {
        flex-basis: 40%;
        margin-bottom: 14px;
        &::after {
            display: none;
        }
        &--icon {
            flex-basis: 50%;
        }
    }
    &__link {
        font-size: 14px;
        text-transform: uppercase;
        color: #6F6F6F;
        &:hover {
            color: $primary-color;
        }
        &--icon {
            display: flex;
            text-transform: none;
            color: #111111;
            font-family: $h-font;
            font-weight: 500;
            flex-shrink: 0;
            font-size: 12px;
            align-items: center;

            &::before {
                content: "";
                flex-shrink: 0;
                display: inline-block;
                background-image: url('/static/images/image-icons/icon-close.svg');
                background-repeat: no-repeat;
                background-position: center;
                width: 30px;
                height: 30px;
                background-color: #E4E4E4;
                border-radius: 50%;
                margin-right: 10px;

            }
        }
    }

}