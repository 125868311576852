.noUi-horizontal .noUi-handle {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    box-shadow: none;
    border-color: #80BFEE;
    top: 50%;
    transform: translateY(-50%);
    right: -12px;
    &:focus {
        outline: none;
    }
    &::after {
        border-radius: 50%;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        background-color: $primary-color;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
    &::before {
        display: none;
    }
}

.noUi-horizontal {
    height: 4px;
}


.noUi-connect {
    background-color: $primary-color;
}