.form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &__modal {
        max-width: 375px;
    }
    &__title {
        flex-basis: 100%;
        text-align: center;
    }
    &__descr {
        @include font;
        color: #6F6F6F;
        font-size: 18px;
        flex-basis: 100%;
        text-align: center;
        line-height: 1.4;
        margin-bottom: 34px;
    }
    &__label {
        border: 1px solid #959595;
        padding: 14px 22px;
        flex-basis: calc(50% - 10px);
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;
        border-radius: 4px;
        & .errors {
            flex-basis: 100%;
            padding: 0;
            margin: 0;
            margin-top: 10px;
            font-size: 12px;
            & li {
                margin: 0;
                &::after {
                    display: none;
                    
                }
            }
        }
        @include xs-block {
            flex-basis: 100%;
        }
        &--clear {
            border: 0;
            padding-left: 0;
            padding-right: 0;
        }
        &--full {
            flex-basis: 100%;
        }
        input {
            flex-grow: 1;
            border: 0;
            outline: none;
        }
        textarea {
            flex-grow: 1;
            border: 0;
            resize: none;
            outline: none;
        }


    }
    &__input-checkbox {
        display: none;
        &:checked + .form__checkbox {
            background: url("/static/images/layout/done.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: 80%;
        }
    }
    &__checkbox {
        width: 24px;
        height: 24px;
        border: 1px solid #959595;
        border-radius: 4px;
        flex-shrink: 0;
        margin-right: 14px;
        cursor: pointer;
    }
    &__policy {
        @include font;
        font-size: 12px;
        color: #ADADAD;
        margin: 0;
        a {
            color: #ADADAD;
        }
    }
    &__submit {
        margin: 0 auto;
        border: 0;
        flex-basis: 310px;
    }
    &__submit-filter {
        margin-top: 10px;
        flex-grow: 1;
        justify-content: center;
        @include lg-block {
            flex-basis: 50%;
            margin: 0;
            margin-bottom: 0;
            & + .form__reset {
                flex-basis: 50%;
                margin-top: 0;
            }
        }
        @include sm-block {
            flex-basis: 100%;
            & + .form__reset {
                flex-basis: 50%;
                margin-top: 30px;
            }
        }
        @include xs-block {
            & + .form__reset {
                margin-top: 30px;
                flex-basis: auto;
            }
        }
    }
    &__wrap {
        flex-basis: 100%;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        &--border {
            padding-top: 24px;
            padding-bottom: 36px;
            border-top: 1px solid #D2D2D2;
            border-bottom: 1px solid #D2D2D2;
        }
    }
    &__range-view {
        flex-basis: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        & span {
            @include font();
            font-size: 12px;
            color: #959595;
        }
        & label {
            padding: 0;
            margin: 0;
            margin: 0 14px;
        }
        & input {
            width: 100%;
            border: 1px solid #868686;
            text-align: center;
            border-radius: 4px;
            padding-top: 6px;
            padding-bottom: 6px;
            color: #565656;
        }
    }
    &__subtitle {
        @include font;
        flex-basis: 100%;
        font-size: 16px;
        color: #000000;
        margin-bottom: 26px;
    }
    &__range {
        flex-basis: 100%;
    }
    &__reset {
        color: #6F6F6F;
        margin-top: 36px;
        background-color: transparent;
        border: 0;
        margin-bottom: 56px;
        padding: 0;
        & .icon-trash {
            margin-right: 12px;
        }
    }
}