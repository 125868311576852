.modal__closer {
    width: 18px;
    height: 18px;
    position: relative;
    &::after {
        content: "";
        position: absolute;
        top: 50%;
        transform: rotate(45deg) translateY(-50%);
        display: block;
        height: 1px;
        width: 100%;
        background-color: #A8A8A8;

    }
    &::before {
        content: "";
        position: absolute;
        top: 50%;
        transform: rotate(-45deg) translateY(-50%);
        display: block;
        height: 1px;
        width: 100%;
        background-color: #A8A8A8;
    }
}