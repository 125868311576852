.list-other {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
    &__item {
        flex-basis: 100%;
        margin-bottom: 28px;
        &::after {
            display: none;
        }
        @include xs-block {
            display: flex;
        }
    }
    &__text {
        margin-left: 48px;
        font-size: 16px;
        display: inline-block;
        color: #111111;
        @include font(500);
    }
    &__icon {
        text-align: center;
        display: inline-block;
        min-width: 30px;
    }
    &__link {
        margin-left: 48px;
        font-size: 16px;
        display: inline-block;
        color: #111111;
        text-decoration: none;
        @include font(500);
        &--decor {
            &::after {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: $primary-color;
            }
        }
    }
}