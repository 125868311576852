.slider-thumbs {
    margin-bottom: 100px;
    &__top {
        margin-bottom: 20px;
    }
    &__wrapper {
        position: relative;
        @include xs-block {
            display: flex;
            justify-content: space-around;
            align-items: center;
            margin-top: 5vw;
        }
    }
    .swiper-container {
        video {
            max-width: 100%;
            max-height: 100%;
        }
        img {
            max-height: 100%;
        }
    }
    .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1px;
        box-sizing: border-box;
    }
    &__thumbs {
        height: 120px;
        .swiper-slide {
            display: flex;
            align-items: center;
            justify-content: center;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
        }
        @include xs-block {
            display: none;
        }
    }
    &__video {
        position: relative;
        &::after {
            content: "";
            display: block;
            width: 40px;
            height: 40px;
            border: 1px solid $primary-color;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            border-radius: 50%;
            background-repeat: no-repeat;
            background-position: center;
            background-color: #fff;
            background-image: url("/static/images/image-icons/play.svg");
        }
    }
    &__top-navigation {
        display: none;
        justify-content: space-around;
        align-items: center;
        margin-top: 5vw;
        @include xs-block {
            display: none;
        }
        .slider-thumbs__button-prev, .slider-thumbs__button-next {
            position: static;
            transform: none;
            width: 40px;
            height: 40px;
        }
    }
    &__button-prev,  &__button-next{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 38px;
        height: 38px;
        border-radius: 4px;
        border: 1px solid $primary-color;
        top: 50%;
        left: -36px;
        transform: translateY(-50%) translateX(-100%);
        &:hover {
            background-color: $primary-color;
            .icon-slider-arrow {
                stroke: #ffffff;
            }
            &.swiper-button-disabled {
                background-color: transparent;
            }
        }
        &.swiper-button-disabled {
            border-color: #E4E4E4;
            .icon-slider-arrow {
                stroke: #E4E4E4;
            }
        }
        @include xs-block {
            position: static;
            transform: none;
        }
    }
    &__button-next {
        right: -36px;
        left: auto;
        transform: translateY(-50%) translateX(100%);
        .icon-slider-arrow {
            transform: rotate(180deg);
        }
        @include xs-block {
            position: static;
            transform: none;
        }
    }
}

.icon-slider-arrow {
    stroke: $primary-color;
}

