.product-detail {
    .grid-row {
        width: 100%;
    }
    &__head {
        @include col;
        @include size(12);
        @include size-xs(12);
        margin-bottom: 50px;
        @include sm-block {
            margin-bottom: 30px;
        }
    }
    &__slider {
        @include col;
        @include size(6);
        @include shift-left(1);
        @include shift-right(1);
        @include size-md(8);
        @include shift-md(2);
        @include size-xs(12);
        @include shift-xs(0);
    }
    &__info {
        @include col;
        @include size(4);
        @include size-md(12);
        @include size-xs(12);
        @include md-block {
            display: flex;
            flex-wrap: wrap;
        }
        .list-info {
            flex-basis: 100%;
            margin-bottom: 30px;
        }
        .price {
            flex-grow: 1;
        }
        .list-links {
            flex-basis: 100%;
            margin-bottom: 70px;
            @include xs-block {
                margin-bottom: 50px;
            }
        }
        .list-links__item--icon {
            flex-basis: calc(50% - 10px);
            margin-right: 10px;
            flex-grow: 1;
            @include xs-block {
                flex-basis: calc(50% - 10px);
            }
        }
    }
    &__title {
        @include col;
        @include size(12);
        @include size-xs(12);
        margin-bottom: 46px;
    }
    &__wrapper {
        @include col;
        @include size(12);
        @include size-xs(12);
        .grid-row {
            width: auto;
            @include xs-block {
                width: 100%;
            }
        }

    }
    &__card-info {
        & .card-info {
            @include col;
            @include size(4);
            @include size-md(6);
            @include size-xs(12);
        }
    }
    &__slider {
        .slider-thumbs__top {
            height: 400px;  
            @include xs-block {
                height: auto;
            }
        }
        .swiper-wrapper {
            @include xs-block {
                height: 250px;
            }
        }
        @include xs-block {
            margin-bottom: 10px;
        }
    }
    &__form {
        @include col;
        @include size(6);
        @include size-md(8);
        @include size-sm(10);
        @include size-xs(12);
        @include shift-left(3);
        @include shift-md-left(2);
        @include shift-sm-left(1);
        @include shift-xs-left(0);
        margin-bottom: 60px;
    }
    &__btn {
        margin-bottom: 38px;
        align-self: flex-start;
        @include md-block {
            flex-basis: 40%;
            text-align: center;
            flex-shrink: 1;
        }
        @include xs-block {
            flex-basis: 100%;
        }
    }
    &__tile-list {
        margin-top: 46px;
        margin-bottom: 70px;
    }
    &__subproduct {
        &--stretch {
            display: flex;
            flex-wrap: wrap;
            @include xs-block {
                
            }
        }
        .card-subproduct {
            @include col;
            @include size(3);
            @include size-md(6);
            @include size-xs(12);
            margin-bottom: 50px;
            &--stretch {
                width: auto;
                flex-basis: 25%;
                flex-shrink: 0;
                @include md-block {
                    flex-basis: 50%;
                }
                @include xs-block {
                    overflow-x: scroll;
                    flex-basis: 100%;
                }
            }
        }
    }
    &__slider-cards {
        @include col;
        @include size(12);
        @include size-xs(12);
        margin-bottom: 100px;
    }
    & .price {
        flex-shrink: 1;
        display: inline-block;
        @include md-block {
            flex-grow: 1;
            flex-basis: auto;
            flex-shrink: 1;
        }
        @include xs-block {
            flex-basis: 100%;
        }
    }
}
