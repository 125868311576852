.wrapper-table {
    @include xs-block {
        overflow-x: scroll;
    }

}

.table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 70px;

    thead {
        @include font(500);
        font-size: 22px;
        text-transform: uppercase;
        @include lg-block {
            font-size: 20px;
        }
        tr {

        }
        td {
            text-align: center;
            padding-bottom: 32px;
            &:first-child {
                text-align: left;
            }
            @include sm-block {
                font-size: 18px;
            }
        }
    }
    tbody {
        tr {
            &:nth-child(2n + 1) {
                background-color: rgba($primary-color, 0.1);
            }
        }
        td {
            text-align: center;
            padding: 16px;
            border: 1px solid #ADADAD;
            border-left-color: transparent;
            border-top-color: transparent;
            border-bottom-color: transparent;
            width: 200px;
            &:first-child {
                text-align: left;
                width: auto;
            }
            &:last-child {
                border-right-color: transparent;
            }
            & p {
                margin: 0;
            }
            @include lg-block {
                font-size: 14px;
                width: 12vw;
            }
            @include sm-block {
                font-size: 13px;
            }
            @include xs-block {
                width: auto;
                min-width: 70px;
            }
        }
    }
}