.card-subproduct {
    &__preview {
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 26px;
        & img {
            max-height: 100%;
            max-width: 100%;
        }
    }
    &__title {
        @include font(500);
        margin: 0;
        font-size: 16px;
        margin-bottom: 30px;
    }
    &__text {
        color: #312D2C;
        line-height: 1.4;
        color: #312D2C;
        font-size: 14px;
        p {
            @include font;
            color: #312D2C;
            font-size: 14px;
            line-height: 1.4;
            color: #312D2C;
            margin-top: 0;
        }
        ul {
            list-style: none;
            li {
                margin-bottom: 10px;
                position: relative;
                &::after {
                    position: absolute;
                    content: "";
                    background: $primary-color;
                    width: 8px;
                    height: 3px;
                    left: 0;
                    top: 7px;
                    transform: translateX(-200%);
                }
            }
        }
    }
}