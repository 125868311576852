.other-page  {
    .grid-row {
        width: 100%;
    }
    &__head {
        @include col;
        @include size(12);
        @include size-xs(12);
        margin-bottom: 50px;
        @include  sm-block {
            margin-bottom: 30px;
        }
    }
    &__content {
        @include col;
        @include size(12);
        @include size-xs(12);
        font-size: 14px;
        color: #312D2C;
    }
    &__slider {
        @include col;
        @include size(12);
        @include size-xs(12);
    }
    &__list {
        @include col;
        @include size(4);
        @include size-md(5);
        @include size-sm(5);
        @include size-xs(12);
        align-items: center;
        @include xs-block {
            margin-bottom: 40px;
        }
        & .list-other__item {
            margin-bottom: 0;
        }
        & .list-other__text,  .list-other__link{
            margin-left: 16px;
        }
    }
    &__button {
        @include col;
        @include size(3);
        @include size-md(4);
        @include size-sm(4);
        @include size-xs(12);
        align-self: flex-start;
        border: 0;
        outline: 0;
        text-align: center;
        @include sm-block {
            font-size: 12px;
            padding: 16px 20px;
        }
    }
    &__tile-list {
        @include col;
        @include size(12);
        @include size-xs(12);
        margin-top: 62px;
        & .tile-sublist__head {
            font-size: 24px;
        }
        & .tile-sublist__link {
            color: $primary-color;
            &:hover {
                text-decoration: underline;
            }
        }
        & .tile-sublist__item {
            @include col;
            @include size(4);
            @include size-xs(12);
            @include sm-block {
                min-height: 28vw;
            }
        }
    }
    .slider-list {
        margin-bottom: 0;
        .swiper-wrapper {
            @include xs-block {
                margin-bottom: 20px;
            }
        }

        .slider-cards__nav {
            display: flex;
            width: auto;
            justify-content: space-between;
            margin-bottom: 20px;
            @include xs-block {
                display: flex;
                justify-content: center;
                & .slider-cards__btn {
                    margin: 0 20px;
                }
            }
        }

    }
}