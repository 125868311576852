.card {
    display: flex;
    flex-direction: column;
    &__link {
        display: flex;
        flex-wrap: wrap;
        flex-grow: 1;
        @include font;
        font-size: 16px;
        color: #111111;
        text-decoration: none;
        line-height: 1.2;
    }
    &__preview {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 26px;
        flex-grow: 1;
        flex-basis: 100%;
    }
    &__title {
        margin-top: auto;
        color: #3D3737;
        @include md-block {
            font-size: 14px;
        }
    }
    &__subtitle {
        line-height: 1.2;
        color: #000000;
        @include font(500);
        @include md-block {
            font-size: 14px;
        }
    }
}