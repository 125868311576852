.main-slider {
    height: 680px;
    background: linear-gradient(to right, #fff 50%, #F0F0F0 50%);
    opacity: 1;
    margin-bottom: 104px;
    @include xm-block {
        height: 610px;
        background: linear-gradient(to right, #fff 0%, #fff 50%, #F0F0F0 50%, #F0F0F0 100%);
    }
    @include sm-block {
        height: 460px;
    }
    @include xs-block {
        height: auto;
        padding-top: 40px;
        background: none;
        margin-bottom: 120px;
    }
    & .grid-row {
        flex-grow: 0;
        display: block;
        width: 100%;
        position: relative;
        @include xm-block {
            display: flex;
            flex-grow: 1;
        }
    }
    &__wrap {
        @include col;
        @include size(12);
    }
    &__slide-name {
        flex-basis: 100%;
        text-align: center;
        transition: all 0.5s ease-out;
        z-index: 0;
        font-size: 24px;
        font-family: $h-font;
        font-weight: 500;
        @include sm-block {
            font-size: 16px
        }
    }
    &__content {
        @include col;
        @include size(6);
        @include size-xm(4);
        @include size-md(6);
        @include size-xs(12);
        position: absolute;
        top: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        z-index: 1;
        justify-content: center;
        @include xs-block {
            position: static;
            order: 1;
        }
    }
    &__slide-title {
        font-size: 48px;
        font-family: $h-font;
        font-weight: 500;
        margin-bottom: 30px;
        text-decoration: none;
        color: #000;
        @include xm-block {
            font-size: 42px;
            margin-top: 0;
            margin-bottom: 60px;
        }
        @include lg-block {
            font-size: 38px;
        }
        @include sm-block {
            margin-bottom: 34px;
        }
        @include xs-block {
            font-size: 24px;
        }
    }
    &__slide-subtitle {
        margin-bottom: 70px;
        font-size: 22px;
        font-family: $font;
        font-weight: 400;
        color: #6F6F6F;
        @include sm-block {
            font-size: 18px;
            margin-bottom: 34px;
        }
        @include xs-block {
            font-size: 14px;
        }
    }
    &__btn {
        align-self: flex-start;
        padding-top: 20px;
        padding-bottom: 20px;
        @include xs-block {
            position: absolute;
            left: 0;
            right: 0;
            text-align: center;
            top: calc(100% + 20px);
        }
    }
    .swiper-container {
        height: 680px;
        @include col;
        @include size(12);
        @include size-md(6);
        @include size-xs(12);
        @include shift-md-left(6);
        @include shift-xs-left(0);
        @include xm-block {
            height: 610px;
        }
        @include sm-block {
            height: 460px;
        }
        @include xs-block {
            order: 2;
            background: linear-gradient(to bottom, #fff 0%, #fff 50%, #F0F0F0 50%, #F0F0F0 100%);
            height: auto;
            padding-bottom: 70px;
        }
    }
    .swiper-pagination {
        @include md-block {
            bottom: 28px;
        }
        @include xs-block {
            bottom: 20px;
        }
    }
    .swiper-slide {
        display: flex;
        align-items: center;
        align-content: center;
        flex-wrap: wrap;
        justify-content: center;
        //transform:translateX(0%);
        transition: all 0.5s ease-out;
    }
    .swiper-slide-prev {
        opacity: 0;
        transition: all 0.5s ease-out;
    }
    .swiper-slide-next {
        filter: grayscale(90%);
        transform:translateX(-25%);
        transition: all 0.5s ease-out;
        @include md-block {
            transform: none;
        }
        .main-slider__slide-name {
            color: #7B7675;
            font-size: 16px;
        }
    }
    .swiper-slide img{
        transition: all 0.5s ease-out;
        max-width: 295px;
        transform:scale(1);
        z-index: 1;
        @include xm-block {
            max-width: 250px;
        }
        @include xs-block {
            max-width: auto;
            margin-bottom: 10px;
        }
    }
    .swiper-slide-active  {
        justify-content: center;
        transform:translateX(0%);
        transition: all 0.5s ease-out;
        & .main-slider__slide-name {
            transform:translateY(100px);
            @include sm-block {
                transform:translateY(40px);
            }
            @include xs-block {
                transform:none;
            }
        }
        & img {
            transform:scale(1.8);
            @include xm-block {
                transform:scale(1.4);
            }
            @include md-block {
                transform:scale(1.2);
            }
            @include xs-block {
                transition: none;
                transform:none;
            }
        }
    }
    .swiper-slide.swiper-slide-duplicate-next {
        opacity: 0;
    }
    .swiper-slide.swiper-slide-duplicate-active img {
        transform:scale(1.8);
        @include xm-block {
            transform:scale(1.4);
        }
        @include md-block {
            transform:scale(1.2);
        }
        @include xs-block {
            transform:none;
        }
    }
    .swiper-slide.swiper-slide-duplicate.swiper-slide-prev {
        transition: none;
    }
    .swiper-slide.swiper-slide-prev img {
        transition: all 0.5s ease-out;
        transform:scale(1.8);
        transition: none;
        @include xm-block {
            transform:scale(1.4);
        }
        @include md-block {
            transform:scale(1.2);
        }
        @include xs-block {
            transform:none;
        }
    }
    .swiper-container .swiper-pagination-bullet {
        width: 6px;
        height: 6px;
        @include md-block {
            margin: 0 7px;
        }

}



}

.swiper-pagination-bullet-active {
    background-color: #030303;
}

.swiper-container {
    width: 100%;
}