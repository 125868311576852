$padding-header: 0 60px;


.header {
    @include xs-block {
        position: relative;
    }
    &__menu {
        @include col-padding;
        @include size(12);
        @include size-lg(12);
        @include size-xs(12);
        border-bottom: 1px solid #E5E5E5;
        padding: $padding-header;
        @include xl-block {
            padding: 0 30px;
        }
        @include lg-block {
            @include col;
            padding: 0;
        }
        @include xs-block {
            display: flex;
            flex-wrap: wrap;
            order: 3;
        }
    }
    &__menu-link {
        display: inline-block;
        font-family: $font;
        color: #6F6F6F;
        font-size: 14px;
        text-decoration: none;
        padding: 16px 0;
        margin-right: 50px;
        @include xs-block {
            flex-basis: 100%;
            font-size: 18px;
        }
    }
    &__wrap {
        @include col-padding;
        @include size(12);
        @include size-lg(12);
        @include size-xs(12);
        margin: 0;
        display: flex;
        padding: $padding-header;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
        @include xl-block {
            padding-left: 30px; 
            padding-right: 30px;
        }
        @include lg-block {
            @include col;
            padding-left: 0px; 
            padding-right: 0px;
        }
        @include xs-block {
            flex-wrap: wrap;
            order: 1;
        }
    }
    &__logo.logo {
        margin-right: auto;
        & img  {
            height: 80px;
        }
        @include sm-block {
            img {
                max-width: 140px;
            }
        }
        @include xs-block {
            display: none;
        }
    }
    &__search.search {
        margin-right: auto;
        flex-basis: 476px;
        align-items: center;
        @include xl-block {
            flex-shrink: 1;
            flex-basis: auto;
            flex-grow: 1;
            margin-right: 100px;
            margin-left: 100px;
        }
        @include lg-block {
            margin-left: 0;
            margin-right: 30px;
            flex-grow: 0;
            padding: 10px;
            position: relative;
            box-sizing: border-box;
            z-index: 1;
            & input {
                position: absolute;
                //flex-basis: 0;
                width: 0;
            }
            &:hover {
                border-right: 0;
                border-radius: 4px 0 0 4px;
                & input {
                    transition: width 0.25s;
                    position: absolute;
                    width: 40vw;
                    top: -1px;
                    height: auto;
                    bottom: -1px;
                    right: 0;
                    transform: translateX(100%);
                    border: 1px solid #D9D9D9;
                    border-left: 0;
                    padding-left: 10px;

                }
            }
        }
        @include md-block {
            padding: 6px;
        }
        @include xs-block {
            flex-basis: 100%;
            margin-right: 0;
            padding: 6px 10px;
            margin-bottom: 22px;
            & input {
                font-size: 13px;
                height: auto;
                position: static;
            }
        }
    }
    &__mail.mail {
        margin-right: 72px;
        @include md-block {
            margin-right: auto;
            font-size: 12px;
        }
        @include xs-block {
            flex-basis: auto;
            margin-bottom: 20px;
            align-self: flex-start;
            font-size: 16px;
        }
    }
    &__phone.phone {
        margin-right: 40px;
        @include md-block {
            font-size: 12px;
        }
        @include xs-block {
            flex-basis: 100%;
            font-size: 18px;
            margin-bottom: 22px;
        }
    }
    &__button {
        @include xs-block {
            text-align: center;
            flex-basis: 100%;
        }
    }
    &__nav {
        @include col-padding;
        @include size(12);
        @include size-lg(12);
        @include size-xs(12);
        padding: $padding-header;
        @include xl-block {
            padding-left: 30px;
            padding-right: 30px;
        }
        @include lg-block {
            @include col;
            padding-left: 0px; 
            padding-right: 0px;
        }
        @include xs-block {
            order: 2;
            border-top: 1px solid #D2D2D2;
        }
    }

    &__mobile {
        display: none;
        @include xs-block {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: 18px;
            padding-bottom: 18px;
            border-bottom: 1px solid #D2D2D2;
            padding-left: 10px;
            padding-right: 10px;
        }
    }
    &__mobile-block {
        @include xs-block {
            display: none;
            &--active {
                display: block;
                position: absolute;
                background: #fff;
                z-index: 2;
                top: 100%;
            }
        }

    }
}
