.category-card {
    margin-bottom: 100px;
    @include md-block {
        margin-bottom: 80px;
    }
    @include xs-block {
        margin-bottom: 50px;
    }
    &__head {
        @include col;
        @include size(12);
        margin-bottom: 36px;
    }
    &__preview {
        @include col;
        @include size(3);
        @include size-xs(12);
        display: flex;
        align-items: center;
        justify-content: center;
        & img {
            max-width: 100%;
        }
        @include md-block {
            align-items: flex-start;
        }
        @include xs-block {
            margin-bottom: 50px;
        }
    }
    &__content {
        @include col;
        @include size(9);
        @include size-xs(12);
        &--full {
            @include size(12);
        }
    }
    &__text {
        color: #312D2C;
        font-size: 14px;
        &--marginb {
            margin-bottom: 60px;
        }
        @include xs-block {
            margin-bottom: 30px;
        }
    }
    &__button {
        color: #6F6F6F;
        font-size: 18px;
        transition: all 0.3s ease-in;
        text-underline-position: under;
        &::before {
            content: '< ';
            // margin-right: 5px;
        }
        &:hover {
            color: $second-color;
        }
    }
    &__list.list-links {
        margin-top: 46px;
        margin-bottom: 46px;
    }
}