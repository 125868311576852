.contact-page {
    &__head {
        @include col;
        @include size(12);
        @include size-xs(12);
        margin-bottom: 50px;
        @include  sm-block {
            margin-bottom: 30px;
        }
    }
    &__list {
        @include col;
        @include size(12);
        @include size-xs(12);
        margin-bottom: 60px;
        & .list-other__text {
            @include sm-block {
                margin-left: 28px;
            }
        }
        & .list-other__link {
            @include sm-block {
                margin-left: 28px;
            }
        }

    }
}