.nav-list {
    margin-bottom: 40px;
    @include lg-block {
        border-bottom: 1px solid #D2D2D2;
        padding-bottom: 20px;
        margin-bottom: 0;
    }
    &__block {
        padding: 0;
        margin: 0;
        @include lg-block {
            display: flex;
            flex-wrap: wrap;
        }
    }
    &__item {
        margin-bottom: 0;
        @include lg-block {
            display: flex;
            flex-basis: 50%;
        }
        @include xs-block {
            display: flex;
            flex-basis: 100%;
        }
        &::after {
            display: none;
        }

    }
    &__link {
        padding: 10px 16px;
        color: #959595;
        box-sizing: border-box;
        display: inline-block;
        border: 1px solid transparent;
        border-radius: 4px;
        text-decoration: none;
        @include lg-block {
            flex-grow: 1;
            text-align: left;
            padding-top: 14px;
            padding-bottom: 14px;
        }
        & span {
            border-bottom: 1px dashed #959595;
        }
        &--active {
            color: $primary-color;
            text-decoration: none;
            border-color: $primary-color;
            & span {
                border-bottom-color: transparent;
            }
        }

    }
}