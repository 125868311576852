.feedback {
    max-width: 375px;
    display: flex;
    flex-wrap: wrap;
    &__title {
        @include font(500);
        font-size: 24px;
        text-align: center;
        margin: 0;
    }
    &__description {
        @include font();
        font-size: 16px;
        color: #6F6F6F;
        text-align: center;
        line-height: 1.4;
    }
    &__wrap {
        flex-basis: 100%;
        box-sizing: border-box;
        margin-bottom: 20px;
        display: flex;
        flex-wrap: wrap;
        padding: 15px;
        border-radius: 4px;
        border: 1px solid #CCCCCC;
        box-sizing: border-box;

        & .policy {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            & label {
                flex-basis: auto;
                display: inline-block;
                font-size: 12px;
                color: #ADADAD;
                line-height: 1.4;
                flex-basis: 76%;
                flex-grow: 1;
                & a {
                    color: #ADADAD;
                }


            }
            input {
                flex-basis: auto;
                width: auto;
                max-width: none;
                margin-right: 20px;
                flex-shrink: 1;
                flex-grow: 0;
            }
            .errors {
                flex-basis: 100%;
            }
        }
        & .errors {
            margin-top: 5px;
            margin: 0;
            padding: 0;
            & li {
                font-size: 12px;
                color: rgb(192, 64, 64);
                margin: 0;
                @include font();
                &::after {
                    display: none;
                }
            }
        }
        & input {
            display: inline-block;
            flex-grow: 1;
            border: 0;
            outline: 0;
            max-width: 100%;
            width: 100%;
            font-size: 14px;
            padding: 0;
            @include font();
        }
        & textarea {
            min-height: 80px;
            resize: none;
            width: 100%;
            max-width: 100%;
            border: 0;
            outline: none;
        }
        &.phone {
            flex-basis: 45%;
            flex-grow: 1;
            margin-right: 10px;
        }
        &.email {
            flex-basis: 45%;
            flex-grow: 1;
            margin-left: 10px;
        }
    }
    &__button {
        flex-basis: 100%;
        border: 0;
    }
}