.card-info {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    &__preview {
        height: 240px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        position: relative;
        border-radius: 14px 14px 0 0;
        &::after {
            content: "";
            position: absolute;
            left: 18px;
            width: 40%;
            height: 24px;
            background-color:rgba(#00A0E3, 0.8);
            border-radius: 24px;
            bottom: 0;
            transform: translateY(50%);
        }
    }
    &__title {
        margin-bottom: 24px;
        @include font(500);
        font-size: 16px;
        display: inline-block;
    }
    &__content {
        flex-grow: 1;
        background-color: #F4F4F4;
        padding-top: 35px;
        padding-bottom: 40px;
        padding-left: 18px;
        padding-right: 18px;
        border-radius: 0 0 14px 14px;
    }
    &__text {
        @include font;
        font-size: 14px;
        line-height: 1.4;
        color: #312D2C;
    }
}