.menu-list {
    padding: 0;
    margin: 0;
    list-style: none;
    &__item {
        &::after {
            display: none;
        }
    }
    &__link {
        color: #ffffff;
        text-decoration: none;
        font-family: $font;
        font-size: 16px;
        font-weight: 400;
        &:hover {
            color: $primary-color;
        }
        @include md-block {
            font-size: 14px;
        }
        @include xs-block {
            font-size: 16px;
        }
    }
}