.logo {
    display: inline-block;
}

.hamburger {
    display: flex;
    background-color: #008BF3;
    border-radius: 4px;
    &:focus {
        outline: none;
    }
}

.search {
    display: flex;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    display: flex;
    padding: 0 18px;
    & input {
        border: 0;
        outline: none;
        height: 42px;
        flex-grow: 1;
        padding: 0;
    }
}

.mail {
    font-family: $h-font;
    color: #111111;
    font-size: 14px;
    font-weight: 500;
    position: relative;
    text-decoration: none;
    flex-shrink: 0;
    &::after {
        content: "";
        left: 0;
        right: 0;
        height: 1px;
        background-color: $primary-color;
        position: absolute;
        bottom: -3px;
    }
}

.phone {
    font-family: $h-font;
    font-style: normal;
    color: #111111;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    flex-shrink: 0;
}


.button {
    display: inline-block;
    background-color: $primary-color;
    border-radius: 4px;
    padding: 18px 40px;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 12px;
    font-family: $font;
    text-decoration: none;
    line-height: 1;
    transition: 0.25s;
    cursor: pointer;
    &--clear {
        border: 0;
    }
    &--transparent {
        display: flex;
        align-items: center;
        color: $primary-color;;
        background-color: transparent;
        border: 1px solid $primary-color;
    }
    &:hover {
        background-color: rgba($primary-color,0.8);
        color: #ffffff;
    }
}


.heading {
    font-size: 32px;
    font-weight: 500;
    font-family: $font;
    color: #111111;
    margin: 0;
    margin-bottom: 60px;
    @include sm-block {
        font-size: 28px;
    }
}

.line-decor {
    position: relative;
    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 2px;
        display: block;
        background: linear-gradient(to right,#111A51 75% ,$primary-color 75%);
    }
}

.copyright {
    display: flex;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
    &__text {
        font-weight: 400;
        font-size: 11px;
        color: #D7D8DF;
        margin: 0;
        margin-right: auto;
    }
    &__developer {
        margin: 0;
        a {
            text-decoration: none;
            font-weight: 400;
            font-size: 11px;
            color: #D7D8DF;
        }
    }
}

.section-text {
    margin: 0;
    font-size: 14px;
    font-family: $font;
    font-weight: 400;
}


.map {
    height: 730px;
    background-color: rgb(219, 219, 219);
    @include sm-block {
        height: 600px;
    }
}

.content {
    @include col;
    @include size(12);
    font-size: 14px;
    color: #312D2C;
    p {
        line-height: 1.4;
    }
}