.slider-cards {
    width: 100%;
    &__title {
        @include col;
        @include size(5);
        @include size-xs(7);
        text-transform: uppercase;
        margin-top: 0;
        margin-bottom: 0;
        align-self: center;
        @include md-block {
            font-size: 20px;
        }
    }
    &__line {
        @include col;
        @include size(4);
        @include size-md(3);
        @include size-xs(5);
        &::after {
            bottom: auto;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    &__nav {
        @include col;
        @include size(3);
        @include size-md(4);
        @include size-xs(12);
        display: flex;
        @include xs-block {
            order: 1;
        }
    }
    &__prev {
        
    }
    &__next {
        transform: rotate(180deg);
    }
    &__pagination {
        position: relative;
        flex-grow: 1;
        display: flex;  
        align-items: center;
        justify-content: center;
        & .swiper-pagination-bullet  {
            margin: 0 7px;
        }
    }
    &__btn {
        width: 38px;
        height: 38px;
        border: 1px solid $primary-color;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &:hover {
            background-color: $primary-color;
            .icon-slider-arrow {
                stroke: #ffffff;
            }
        }
    }
    &__container {
        @include col;
        @include size(12);
        @include size-xs(12);
        margin-top: 30px;
        @include xs-block {
            margin-bottom: 30px;
        }
    }
    &__slide {
        display: flex;
        flex-direction: column;
        text-decoration: none;
    }
    &__preview {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 26px;
    }
    &__descr {
        @include font;
        font-size: 16px;
        color: #3D3737;
    }
    &__subtitle {
        @include font(500);
        font-size: 16px;
        color: #111111;
        align-self: stretch;
    }
}