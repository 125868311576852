.about {
    margin-bottom: 92px;
    @include md-block {
        margin-top: 60px;
        margin-bottom: 60px;
    }
    &__preview {
        @include col;
        @include size(5);
        @include size-xs(12);
        overflow: hidden;
        line-height: 1;
        align-self: flex-end;
        text-align: center;
        & img {
            border-radius: 14px;
            max-width: 100%;
        }
        @include md-block {
            align-self: flex-start;
        }
        @include xs-block {
            margin-bottom: 28px;
        }
    }
    &__description {
        @include col;
        @include size(7);
        @include size-xs(12);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    &__title {
        line-height: 1.4;
        margin: 0;
        font-size: 32px;
        font-weight: 500;
        font-family: $h-font;
        margin-bottom: 30px;
        @include md-block {
            margin-bottom: 20px;
            font-size: 24px;
        }

    }
    &__link {
        align-self: flex-start;
        background-color: transparent;
        color: $primary-color;
        border: 1px solid $primary-color;
        padding-top: 21px;
        padding-bottom: 21px;
        font-weight: 500;
        @include xs-block {
            align-self: stretch;
            text-align: center;
        }
    }
    &__text {
        margin-bottom: 10px;
        p {
            font-family: $font;
            font-size: 14px;
            margin-bottom: 20px;
            & b {
                font-size: 16px;
                font-family: $h-font;
                font-weight: 500;
            }
        }
    }
}