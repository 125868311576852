.price {
    margin-bottom: 30px;
    &__text {
        @include font;
        color: #312D2C;
        font-size: 18px;
        margin-right: 30px;
    }
    &__sum {
        @include font(500);
        color: $primary-color;
        font-size: 24px;
    }
    &__subtext {
        font-size: 13px;
        @include font;
        color:  #6F6F6F;
        margin-top: 24px;
    }
}