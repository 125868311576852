.tile-sublist {
    margin-bottom: 78px;
    @include md-block {
        margin-bottom: 58px;
    }
    &__head {
        @include col;
        @include size(12);
        @include xs-block {
            margin-bottom: 30px;
        }
    }
    &__item {
        @include col;
        @include size(3);
        @include size-xs(12);
        margin-bottom: 30px;
        display: flex;
        min-height: 250px;
        word-break: break-all;
        @include lg-block {
            min-height: 21vw;
            
        }
        @include xs-block {
            min-height: auto;
            margin-bottom: 25px;
            word-break: normal;
        }
    }
    &__link {
        padding: 32px;
        display: flex;
        flex-basis: 100%;
        flex-direction: column;
        border: 1px solid #ABABAB;
        text-decoration: none;
        border-radius: 8px;
        transition: all 0.20s ease-in;

        &:hover {
            border-color: $primary-color;
            border-radius: 0 30px 0 30px;
            .tile-sublist__text {
                color: $primary-color;
            }
            box-shadow: -20px 20px 30px 0px  #b3b3b3;
        }
        @include lg-block {
            padding: 2vw;
        }
        @include xs-block {
            padding: 25px 16px;
        }
    }
    &__icon {
        flex-basis: 100%;
        margin-bottom: 40px;
        @include lg-block {
            margin-bottom: 2vw;
            width: 40%;
        }
        @include xs-block {
            margin-bottom: 30px;
            width: 70px;
        }
    }
    &__text {
        flex-basis: 100%;
        font-size: 18px;
        line-height: 1.2;
        font-family: $font;
        color: #111111;
        @include lg-block {
            font-size: 2vw;
        }
        @include xs-block {
            font-size: 16px;
            margin-bottom: 20px;
        }
    }
}