.about-page {
    margin-bottom: 40px;
    &__head {
        @include col;
        @include size(12);
        @include size-xs(12);
        margin-bottom: 50px;
        @include  sm-block {
            margin-bottom: 30px;
        }
    }
    &__preview {
        @include col;
        @include size(4);
        @include size-xs(12);
        @include xs-block {
            text-align: center;
        }
    }
    &__preview-image {
        display: block;
        margin-bottom: 58px;
        & img {
            border-radius: 14px;
        }

    }
    &__slider {
        margin-bottom: 46px;
        & .slider-list__head {
            font-size: 18px;
        }
    }
    &__banner {
        @include col;
        @include size(12);
        @include size-xs(12);
    }
    &__banner-image {
        text-align: center;
        @include xm-block {
            display: block;
            & img {
                border-radius: 14px;
            }
        }
    }
    &__content {
        @include col;
        @include size(12);
        @include size-xs(12);
        & h2 {
            margin-bottom: 30px;
            margin-top: 0;  
        }
        & h3 {
            color: #312D2C;
            margin-bottom: 22px;
            margin-top: 0;  
        }
        & p {
            color: #312D2C;
            margin-bottom: 22px;
            font-size: 14px;
            margin-top: 0;
            line-height: 1.4;
        }
        &--small {
            @include col;
            @include size(8);
            @include size-xs(12);
        }
    }
    &__advantages {
        & .heading {
            font-size: 18px;
            margin-bottom: 36px;
            color: #312D2C;
        }
        & .advantage__wrap {
            margin-left: 36px;
            @include xs-block {
                margin-left: 16px;
            }
        }
        & .advantage__text {
            margin-top: 0;
            margin-bottom: 0;
        }
        & .advantages__item-link:hover {
            .advantage__text {
                color: $primary-color;

            }
        }
    }
}