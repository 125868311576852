$max-row-width: 1008;
$grid-columns: 12;
$grid: (
  small: (
    from: 0,
    to: 977,
    gutter: 12,
    spacer: 8
  ),
  large: (
    from: 978,
    gutter: 30
  )
);
$body-font-size: 16px;
$font:  'Roboto', 'Arial', sans-serif;
$h-font: 'Roboto', 'Arial', sans-serif;

$t: transparent;

$primary-color: #00A0E3;
$second-color: #008BF3;
$alert-color: #b50000;

$default-transition: all 0.4s;


@mixin font($weight:400) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
}