

html {
  height: 100%;
}
body {
  display: flex;
  justify-content: center;
  min-height: 100%;
  flex-wrap: wrap;
  font-size: $body-font-size;
  font-family: $font;

}

#wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

#main {
  flex-grow: 1;
}
.grid-wrapper-full {
  @include wrapper-full();
}

.grid-wrapper {
  @include wrapper();
  display: flex;
  flex-grow: 1;
}

.grid-row {
  flex-grow: 1;
  @include row-flex();
}

img {
  max-width: 100%;
}