.breadcrumbs {
    margin-top: 19px;
    margin-bottom: 28px;
    @include xm-block {
        margin-bottom: 20px;
    }
    &__block {
        @include col;
        @include size(12);
        padding: 0;
        list-style: none;
        display: flex;
        margin-top: 0;
        margin-bottom: 0;
    }
    &__elem {
        margin-bottom: 0;
        &::after {
            display: none;
        }
        @include xs-block {
            display: none;
            &:last-child {
                display: block;
                &::before {
                    content: "...";
                    display: inline-block;
                }
            }
            .breadcrumbs__text {
                margin-left: 10px;
            }
        }
    }
    &__link {
        color: #959595;
        text-decoration: none;
        font-size: 12px;
        &::after {
            content: "-";
            display: inline-block;
            margin: 0 18px;
        }
    }
    &__text {
        font-size: 12px;
        color: #111111;
    }
}