.category-list {
    &__heading {
        @include col;
        @include size(7);
        @include size-xs(12);
        @include md-block {
            font-size: 24px;
            margin-bottom: 50px;
        }
        @include xs-block {
            font-size: 18px;
            margin-bottom: 38px;
        }
    }

}

.category-item {
    min-height: 420px;
    margin-bottom: 80px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    @include md-block {
        margin-bottom: 20px;
        min-height: 300px;
    }
    @include xs-block {
        margin-bottom: 42px;
    }
    &__content {
        margin-top: auto;
        display: flex;
        @include col;
        @include size(6);
        @include size-md(7);
        @include size-sm(8);
        @include size-xs(12);
        margin-bottom: 46px;
        @include xs-block {
            margin-bottom: 22px;
        }
        & .icon-arrow {
            fill: #111111;
            @include xs-block {
                width: 40px;
            }
        }
    }
    &__link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-grow: 1;
        padding: 45px 60px;
        background-color: #fff;
        font-size: 24px;
        font-weight: 500;
        font-family: $h-font;
        color: #111111;
        text-decoration: none;
        border-radius: 0 30px 0 30px;
        @include md-block {
            padding-top: 34px;
            padding-bottom: 34px;
            font-size: 22px;
        }
        @include xs-block {
            padding: 24px 30px;
            font-size: 16px;
        }
        &:hover {
            color: $primary-color;
            .category-item__text {
                &::after {
                    background-color: $primary-color;
                }
            }
            & .icon-arrow {
                fill: $primary-color;
            }
        }

    }
    &__text {
        position: relative;
        &::after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 1px;
            background-color: transparent;
        }
    }

    
}