.catalog {
    margin-bottom: 100px;
    &__head {
        @include col;
        @include size(12);
        @include size-xs(12);
        margin-bottom: 50px;
        @include lg-block {
            margin-bottom: 20px;
        }
        @include  sm-block {
            margin-bottom: 30px;
        }
    }
    &__aside {
        @include col;
        @include size(3);
        @include size-lg(12);
        @include size-xs(12);
        padding-right: 10px;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        @include lg-block {
            border-bottom: 1px solid #D2D2D2;
            margin-bottom: 30px;
            padding-right: 0;
            & .form + .button {
                margin-bottom: 40px;
                
            }
        }
        @include xs-block {
            .button.button--transparent {
                // display: none;
            }
        }
    }
    &__list {
        @include col;
        @include size(9);
        @include size-lg(12);
        @include size-xs(12);
        display: flex;
        align-self: flex-start;

        & .card {
            @include col;
            @include size(4);
            @include size-xs(12);
            margin-bottom: 30px;
        }
        &--full {
            @include size(12);
            & .card {
                @include size(3);
                @include size-lg(4);
                @include size-sm(6);
                @include size-xs(12);
            }
        }
    }
    &__aside-head {
        margin-bottom: 28px;
        font-size: 18px;
        color: #000000;
        @include font(500);
        display: flex;
        align-items: center;
        text-align: left;
        .icon-arrow-menu {
            display: none;
            width: 20px;
            height: 10px;
        }
        @include lg-block {
            flex-basis: 100%;
            border: 1px solid #00A0E3;
            padding: 16px;
            border-radius: 4px;
            .icon-arrow-menu {
                display: inline-block;
                stroke: $primary-color;
                transition: 0.2s ease-in;
            }
            &::after {
                content: "";

            }
            &.roll-btn-active {
                color: white;
                background-color: $primary-color;
                .icon-arrow-menu {
                    transform: rotate(180deg);
                    stroke: #ffffff;
                }
            }
        }
        @include sm-block {
            // background-color: $primary-color;
            // color:#fff;
            // .icon-arrow-menu {
            //     stroke: #fff;
            // }
        }
        @include xs-block {
            background-color: #fff;
            color: #000000;
            .icon-arrow-menu {
                stroke: #000000;
            }
        }

    }
    &__aside-title {
        @include lg-block {
            flex-grow: 1;
        }
    }
    &__list-head {
        @include col;
        @include size(12);
        @include size-xs(12);
        margin-top: 0;
        margin-bottom: 50px;
    }
    &__nav-list {
        @include lg-block {
            display: none;
            &.roll-active {
                display: flex;
            }
        }
    }
    &__filter {
        @include lg-block {
            display: none;
            &.roll-active {
                display: flex;
            }
            .form-field {
                flex-basis: 50%;
            }
            .form__wrap {
                box-sizing: border-box;
                flex-basis: 50%;
                padding-left: 20px;
                padding-right: 20px;
                padding-top: 0;
                margin-bottom: 30px;
            }
            .form__buttons {
                flex-basis: 100%;
                padding: 0;
            }
            .form__reset {
                margin-bottom: 0px;
            }
        }
        @include xs-block {
            .form-field {
                flex-basis: 100%;
            }
            .form__wrap {
                flex-basis: 100%;
            }
        }
        .form__reset {
            cursor: pointer;
            &:hover {
                span {
                    color: $primary-color;
                }
                .icon-trash {
                    stroke: $primary-color;
                }
            }
        }
        .form__wrap--border {
            border-top: 0;
        }
        .form__label {
            padding: 0;
            margin-bottom: 12px;
            align-items: center;
        }
        .form__input-checkbox:checked + .form__checkbox {
            background: url("/static/images/image-icons/done-white.svg");
            background-size: 50%;
            background-color: $primary-color;
            background-repeat: no-repeat;
            background-position: center;
            border-color: $primary-color;
        }
    }
    &__filter-wrap {
        margin-bottom: 20px;
        @include lg-block {
            .form__label--full {
                flex-basis: 50%;
            }
        }
        @include xs-block {
            .form__label--full {
                flex-basis: 100%;
            }
        }
    }
    &__pagination {
        @include col;
        @include size(12);
        @include size-xs(12);
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        margin-top: 100px;
        @include md-block {
            justify-content: center;
            margin-top: 60px;
            .pagination__item  {
                margin: 0 20px;
                flex-grow: 1;
                text-align: center;
            }
            .pagination__block {
                flex-grow: 1;
            }
        }
        @include md-block {
            .pagination__item  {
                margin: 0 10px;
            }
        }


    }
}