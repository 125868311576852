$modal-bg: rgba(0, 0, 0, 0.76) !default;

$modal-content-radius: 5px !default;
$modal-content-shadow: 3px 4px 35px rgba(92, 99, 100, 0.27) !default;
$modal-content-padding: 30px !default;
$modal-content-background: #fff !default;

$modal-closer-color: $modal-bg !default;
$modal-closer-size: 30px !default;

$modal-preloader-size: 30px;

.modal__bg {
  position: fixed;
  height: 100%;
  width: 100%;
  background: white;
  background: $modal-bg;
  z-index: 10000;
  display: none;
  top: 0;
  left: 0;
  overflow-y: scroll;

  &.opened {
    display: block;
  }
}

.modal__layout {
  display: none;
  position: relative;
  z-index: 11000;
  cursor: default;
  padding: 80px 0;
  margin: 0 auto;
  min-height: 100%;
  align-items: center;
  justify-content: center;

  &.opened {
    display: flex;
  }
}

.modal__container {
  padding: $modal-content-padding;
  border-radius: $modal-content-radius;
  background: $modal-content-background;
  box-shadow: $modal-content-shadow;
  position: relative;
}

.modal__content {
  img {
    max-width: 100%;
    height: auto;
  }
}

.modal__closer {
  position: absolute;
  top: 10px;
  right: 10px;

  font-size: $modal-closer-size;
  color: $modal-closer-color;

  text-decoration: none;
  cursor: pointer;
}

.modal__loader {
  display: block;
  position: fixed;
  z-index: 3000;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: $modal-bg;
  transition: 0.3s all;
  opacity: 0;
  visibility: hidden;
}

body.modal-loading {
  overflow: hidden;
  $preloaderSize: 100px;

  .modal__loader {
    opacity: 1;
    visibility: visible;

    &:before, &:after {
      content: '';
      position: fixed;
      width: $preloaderSize;
      height: $preloaderSize;
      left: 50%;
      top: 50%;
      margin-left: -$preloaderSize/2;
      margin-top: -$preloaderSize/2;
      background-color: #000;
      border-radius: $preloaderSize/2;
      transform: scale(0);
    }

    &:before {
      background-color: lighten($primary-color, 15%);
      animation-name: pulse;
      animation-iteration-count: infinite;
      animation-duration: 1.4s;
      animation-delay: 0.4s;
    }

    &:after {
      background-color: $primary-color;
      animation-name: pulse;
      animation-iteration-count: infinite;
      animation-duration: 1.4s;
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}