.pagination {

    &__block {
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0;
    }
    &__item {
        &::after {
            display: none;
        }
        margin: 0 34px;
        @include xs-block {
            margin: 0 14px;
        }
    }
    &__link {
        font-size: 18px;
        color: #7B7675;
        text-decoration: none;
        &--active {
            font-size: 22px;
            @include font(500);
            color: $primary-color;
        }
        &--prev, &--next {
            display: inline-block;
            border: 1px solid $primary-color;
            border-radius: 4px;
            &::after {
                content: "";
                display: block;
                width: 50px;
                height: 50px;
                background-image: url('/static/images/image-icons/arrow.svg');
                background-size: 40%;
                background-position: center;
                background-repeat: no-repeat;
                @include xs-block {
                    width: 36px;
                    height: 36px;
                }
            }
            
        }
        &--prev {
            &::after {
                transform: rotate(180deg);
            }
        }
    }

}