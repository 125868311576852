.footer {
    padding-top: 58px;
    background-color: #131C53;
    &__contacts {
        @include col;
        @include size(4);
        @include size-xs(12);
        display: flex;
        flex-direction: column;
        @include xs-block {
            margin-bottom: 46px;
        }
    }
    &__menu {
        @include col;
        @include size(4);
        @include size-xs(12);
        @include xs-block {
            margin-bottom: 46px;
        }
    }
    &__wrap {
        @include col;
        @include size(4);
        @include size-xs(12);

    }
    &__copyright {
        @include col;
        @include size(12);
        border-top: 1px solid #424D8B;
        @include md-block {
            flex-wrap: wrap;
            
            .copyright__text {
                text-align: center;
                flex-basis: 100%;
                font-size: 12px;
                @include xs-block {
                    margin-bottom: 10px;
                }
            }
            .copyright__developer {
                a {
                    font-size: 12px;
                }
                flex-basis: 100%;
                text-align: center;

            }
        }
    }
    &__logo {
        margin-bottom: 28px;
    }
    &__title {
        text-transform: uppercase;
        color: #ffffff;
        margin-bottom: 14px;
        font-family: $font;
        font-weight: 500;
    }
    &__text {
        color: #ffffff;
        font-size: 16px;
        font-family: $font;
        font-weight: 400;
        margin-bottom: 10px;
        @include md-block {
            font-size: 14px;
        }
        @include xs-block {
            font-size: 16px;
        }
    }
    &__question {
        border: 1px solid #424D8B;
        padding: 14px 19px;
        margin-bottom: 32px;
        @include md-block {
            font-size: 14px;
        }
    }
    &__question-btn.button {
        text-align: center;
        display: block;
        padding-left: 0;
        padding-right: 0;
    }
    &__question-text {
        color: #ffffff;
        font-size: 16px;
        font-family: $h-font;
        font-weight: 500;
        margin-bottom: 19px;
        line-height: 1.4;
    }
    .footer__phone {
        color: #ffffff;
        font-size: 16px;
        font-family: $font;
        font-weight: 400;
        margin-bottom: 10px;

    }
    .footer__mail {
        color: #ffffff;
        font-size: 16px;
        font-family: $font;
        font-weight: 400;
        margin-bottom: 10px;
        &::after {
            display: none;
        }
    }

}


.footer__menu {
    & .menu-list__item {
        margin-bottom: 22px;
        &:last-child {
            margin-bottom: 0;
        }
    }

}

.footer__wrap {
    & .menu-list__item {
        margin-bottom: 19px;
    }
    & .menu-list__link {
        color:rgba( #ffffff, 0.8);
    }
    
}