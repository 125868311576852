h1, h2, h3, h4, h5, h6 {
  font-family: $h-font;
  font-weight: 500;
}

$h1-size: 32px;
$h2-size: 28px;
$h3-size: 24px;
$h4-size: 18px;
$h5-size: 16px;
$h6-size: 14px;

h1 {
  font-size: $h1-size;
}

h2 {
  font-size: $h2-size;
  @include sm-block {
    font-size: 24px;
  }
}

h3 {
  font-size: $h3-size;
  @include sm-block {
    font-size: 22px;
  }
}

h4 {
  font-size: $h4-size;
}

h5 {
  font-size: $h5-size;
}

h6 {
  font-size: $h6-size;
}

p {
  font-family: $font;
  font-weight: 400;
}


ul {
  list-style: none;
  margin-top: 30px;
  margin-bottom: 30px;
  li {
    position: relative;
    margin-bottom: 14px;
    &::after {
      content: "";
      position: absolute;
      display: block;
      width: 4px;
      height: 1px;
      background: #312D2C;
      left: 0;
      top: 50%;
      transform: translateY(-50%) translateX(-200%);
    }
  }
}

ol {
  li {
    position: relative;
    margin-bottom: 14px;
  }
}