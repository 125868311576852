.slider-list {
    margin-bottom: 100px;
    @include md-block {
        margin-bottom: 70px;
    }
    .grid-row {
        width: 100%;
    }
    .swiper-slide {
        text-align: center;
    }
    &__head {
        @include col;
        @include size(8);
        @include size-md(6);
        @include size-xs(4);
        font-weight: 500;
        margin-bottom: 0;
        margin-top: 0;
        @include xs-block {
            font-size: 18px;
        }
    }
    &__wrap {
        @include col;
        @include size(12);
        margin-top: 52px;
        @include xs-block {
            margin-top: 32px;
        }
    }
    &__line {
        @include col;
        @include size(4);
        @include size-md(6);
        @include size-xs(8);
    }
    .swiper-wrapper{
        align-items: center;
        margin-bottom: 30px;
        @include xs-block {
            margin-bottom: 0;
        }
    }
    .swiper-pagination {
        position: relative;
    }
}


