.tabs {
    &__block {
            display: flex;
            flex-wrap: wrap;
            margin: 0;
            padding: 0;
            list-style: none;
            border-bottom: 1px solid #E0E0E0;
            margin-bottom: 58px;
    }
    &__wrap {
        @include col;
        @include size(3);
        @include size-md(3);
        @include size-xs(12);
        display: flex;
        @include xs-block {
            flex-direction: column;
        }
    }
    &__btn {
        flex-grow: 1;
        @include font(500);
        text-align: center;
        background-color: transparent;
        border: 0;
        color: #868686;
        padding-bottom: 25px;
        position: relative;
        &:focus {
            outline: none;
        }
        & .svg-icon {
            display: none;
        }
        @include xs-block {
            padding-top: 20px;
            padding-bottom: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            & .svg-icon {
                display: block;
                stroke: $primary-color;
                margin-left: 20px;
                width: 18px;
                height: 8px;
                transform: rotate(180deg);
                transition: transform 0.25s;
            }
            &::after {
                content: "";
                position: absolute;
                display: block;
                bottom: 0;
                left: 0;
                right: 0;
                height: 1px;
                background-color: #E0E0E0;
            }
        }
        &--active {
            color: $primary-color;
            &::after {
                content: "";
                position: absolute;
                display: block;
                height: 6px;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: $primary-color;
            }
            & .svg-icon {
                transform: rotate(0);
            }
        }

    }
    &__item {
        display: none;
        &--active {
            display: block;
            @include xs-block {
                display: none;
            }
        }
        &--mobile {
            display: none;
            @include xs-block {
                &.tabs__item--active {
                    display: block;
                }
            }
        }

    }
    &__content {
    }
    &__text {
        @include font;
        p {
            color: #312D2C;
            font-size: 14px;
            line-height: 1.4;
        }
        h2,h3,h4,h5,h6 {
            text-transform: uppercase;
        }
        li {
            @include font;
            font-size: 14px;
        }

    }
}