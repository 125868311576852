.tile-list {
    margin-bottom: 100px;
    @include md-block {
        margin-bottom: 80px;
    }
    &__head {
        margin-bottom: 46px;
        @include col;
        @include size(12);
        @include md-block {
            margin-bottom: 26px;
        }
    }
    &__item {
        @include col;
        @include size(3);
        @include size-md(6);
        @include size-xs(12);
        display: flex;
    }
    &__link {
        text-decoration: none;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        &--reverse {
            flex-direction: column-reverse;
        }
    }
    &__preview {
        margin-bottom: 26px;
        text-align: center;
        height: 200px;
        display: flex;
        justify-content: center;
        & img {
            max-height: 100%;
        }
        &--radius {
            border-radius: 10px;
        }
        &--bg {
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
        @include md-block {
            justify-content: flex-start;
        }
        @include xs-block {
            justify-content: center;
        }
    }
    &__text {
        text-transform: uppercase;
        font-family: $h-font;
        font-weight: 500;
        font-size: 16px;
        color: #111111;
        &--little {
            text-transform: none;
            margin-bottom: auto;
            padding-bottom: 10px;
        }
        @include xs-block {
            text-align: center;
        }
    }
    &__wrap-text {
        @include col;
        @include size(12);
        margin-bottom: 52px;
    }
    &__bottom-text {
        @include col;
        @include size(12);
        margin-top: 82px;
        line-height: 1.4;
        color:  #312D2C;
        @include md-block {
            margin-top: 60px;
        }
    }

}