.service-page {
    &__head {
        @include col;
        @include size(12);
        @include size-xs(12);
        margin-bottom: 50px;
        @include  sm-block {
            margin-bottom: 30px;
        }
    }
    &__content {
        margin-bottom: 50px;
    }
    &__tile-list {
        @include col;
        @include size(12);
        @include size-xs(12);
    }
}