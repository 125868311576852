.list-info {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 20px;
    border-bottom: 1px solid #B9B9B9;
    margin-bottom: 24px;
    @include md-block {
        border-top: 1px solid #B9B9B9;
        padding-top: 30px;
    }
    &__name {
        flex-basis: 50%;
        display: inline-block;
        box-sizing: border-box;
        margin-bottom: 12px;
        font-size: 14px;
        color: #312D2C;
        @include font;
        &:last-child {
            margin-bottom: 0;
        }
        @include md-block {
            flex-basis: 25%;
        }
        @include xs-block {
            flex-basis: 50%;
        }
    }
    &__value {
        flex-basis: 50%;
        display: inline-block;
        text-align: left;
        margin: 0;
        padding-left: 15px;
        box-sizing: border-box;
        margin-bottom: 12px;
        @include font(500);
        color: #312D2C;
        &:last-child {
            margin-bottom: 0;
        }
        @include md-block {
            flex-basis: 25%;
        }
        @include xs-block {
            flex-basis: 50%;
        }
    }
}