$marginb-advantage: 50px;

.advantages {
    margin-bottom: 114px - $marginb-advantage;
    &__title {
        @include col;
        @include size(12);
        margin-bottom: 68px;
        @include md-block {
            font-size: 24px;
            margin-bottom: 48px;
        }
    }
    &__item {
        @include col;
        @include size(6);
        @include size-xs(12);
    }
    &__item-link {
        display: flex;
        text-decoration: none;

        &:hover {
            .advantage__title {
                color: $primary-color;

            }
        }
    }
    @include md-block {
        margin-bottom: 0;
    }
    @include xs-block {
        margin-bottom: 50px;
    }
}

.advantage {
    margin-bottom: $marginb-advantage;
    display: flex;
    align-items: flex-start;
    @include xs-block {
        margin-bottom: 30px;
    }
    &__icon {
        min-width: 56px;
        min-height: 56px;
        flex-basis: 56px;
        & img {
            max-width: 100%;
        }
        @include md-block {
            min-width: 39px;
            min-height: 39px;
        }
    }
    &__wrap {
        margin-left: 56px;
        @include lg-block {
            margin-left: 15px;
        }
    }
    &__title {
        margin: 0;
        font-size: 16px;
        font-weight: 500;
        font-family: $font;
        color: #312D2C;
    }
    &__text {
        font-size: 16px;
        line-height: 1.4;
        color: #312D2C;
        @include md-block {
            font-size: 14px;
        }
    }
}
